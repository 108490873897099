<template>
    <div class="page-content">
        <!-- inner page banner -->
        <div class="dlab-bnr-inr overlay-black-dark banner-content bnr">
            <div class="container">
                <div class="dlab-bnr-inr-entry">
                    <h1 class="text-white">Admissions</h1>
                    <!-- Breadcrumb row -->
                    <div class="breadcrumb-row">
                        <ul class="list-inline">
                            <li>
                                <router-link :to="{name: 'Home'}">Home</router-link>
                            </li>
                            <li>Admissions</li>
                        </ul>
                    </div>
                    <!-- Breadcrumb row END -->
                </div>
            </div>
        </div>

        <!-- contact area -->
        <div class="content-block">
            <!-- Code Box -->
            <div class="section-full content-inner bg-white video-section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="sort-title clearfix text-center">
                                <h4>JOIN OUR FAMILY</h4>
                            </div>
                            <p style="text-align:center">
                                We appreciate your interest in joining Citadel International School. We offer an
                                unprecedented educational experience for your child, where his holistic development and
                                success are our top priority. Please fill in the form below with the requested
                                information, and one of our admission team will contact you shortly to arrange a date
                                for the assessment.
                            </p>
                            <p style="text-align:center">
                                <a  onclick="this.blur();" class="btn btn-primary" style="color: blue;background-color: white !important;" href="https://docs.google.com/forms/d/e/1FAIpQLSfVt2LR-v46pW-HPaw_sSF_ATA2TV4no6a705_dgu3_GCAa2A/viewform"
                                    target="_blank" rel="noopener noreferrer">
                                    Apply Now  <i class="fa fa-external-link"></i>
                                </a>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .bnr {
        background-image: url("~@/assets/imgs/bnr1.jpg") !important;
    }
</style>